import React from 'react';
import Layout from '../../components/App/Layout';
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {Link} from 'gatsby';
import { getDownloadUrlByOsVersion } from '../../utils/redirect';
import { osName } from 'react-device-detect';
import { graphql } from 'gatsby';
const img = '/img/desktop/BlueMail-Windows-Application.png';

const windowsPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO 
            postTitle="Windows | BlueMail App"
            postDescription="BlueMail - The Most Powerful Email App is available for Windows 10 and Windows 11"
            postURL="desktop/windows" 
        />
        <div className="container pt-120 pb-100">
            <div className='row'>
            <div className='col-12 center-text'>
                <h2 className="mb-30">BlueMail - The Most Powerful Email App for Windows</h2>
                <a href={osName === 'Windows' ? getDownloadUrlByOsVersion() : getDownloadUrlByOsVersion()} target="_blank" rel="noreferrer">
                    <img src={img} style={{maxHeight: '500px'}} className='desktop-img' alt='BlueMail Windows 7, Windows 8, and Windows 10' />
                </a>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '30px'}}>
                    <a href={osName === 'Windows' ? getDownloadUrlByOsVersion() : getDownloadUrlByOsVersion()} target="_blank" rel="noreferrer" className='default-btn mt-20' >
                        Download Windows 10, 11
                    </a>

                    <a href="https://download.bluemail.me/BlueMail-Desktop-Installer.exe" target="_blank" rel="noreferrer" className='default-btn mt-20' >
                        Download Windows 10, 11 (exe)
                    </a>

                    <a href="https://download.bluemail.me/BlueMail/BlueMail-1.140.34.exe" target="_blank" rel="noreferrer" className='default-btn mt-20' >
                        Download Windows 7, 8 (exe)
                    </a>
                </div>
                <div className="mt-30">
                    <p>Not on Windows?<br/>Download for <Link to='/desktop/mac/'><b style={{color:'#1F6BF1'}}>Mac</b></Link> or <Link to='/desktop/linux/'><b style={{color:'#1F6BF1'}}>Linux</b></Link></p>
                </div>
            </div>
            
            </div>
        </div>
        <Footer />
    </Layout>
)

export default windowsPage

export const query = graphql`
query WindowsPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
